import React, { useState, useEffect, useContext } from "react"
import { Helmet, HelmetProvider } from "react-helmet-async";
import BtnSubmit from "../components/BtnSubmit";
import BtnLoading from "../components/BtnLoading";
import { TextField, IconButton } from '@mui/material';
import Logout from "../img/icons/log_out.svg";
import Axios from "axios"
import { useNavigate } from "react-router-dom"
import { AuthContext } from "../context/auth";
import Message from '../components/Message';

const Cuenta = () => {
    const { user, setUser } = useContext(AuthContext);
    const [data, setData] = useState({
        nombre: "",
        apellido: "",
        loading: false,
        errorNombre: "",
        errorApellido: ""
    });
    const { nombre, apellido, loading, errorNombre, errorApellido } = data;
    const [cantidades, setCantidades] = useState({
        cantCarpetas: 0,
        cantMetas: 0
    })
    const { cantCarpetas, cantMetas } = cantidades
    const navigate = useNavigate()
    const [creado, setCreado] = useState(false)
    const [textoMensaje, setTextoMensaje] = useState("")

    useEffect(() => {
        if (user.id !== "") {
            Axios.get(process.env.REACT_APP_API_BASE_URL + "/usuarios/" + user.id).then(
                (response) => {
                    if (response.data) {
                        setCantidades(response.data)
                    }
                }
            );
        }
        setData({
            loading: false,
            errorNombre: "",
            errorApellido: "",
            nombre: user.nombre,
            apellido: user.apellido
        })
    }, [user.id, user.nombre, user.apellido]);

    const handleChange = (e) => {
        setData({ ...data, [e.target.name]: e.target.value });
    };

    const handleLogOut = () => {
        var result = window.confirm("Seguro de cerrar sesión?");
        if (result) {
            Axios.delete(process.env.REACT_APP_API_BASE_URL + "/login").then((response) => {
                if (response.status === 200) {
                    navigate("/login");
                }
            });
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault()

        setData({
            ...data,
            errorNombre: false,
            errorApellido: false,
            loading: true,
        });

        if (!nombre && !apellido) {
            setData({
                ...data,
                errorNombre: "Ingresa un nombre",
                errorApellido: "Ingresa un apellido",
                loading: false
            });
        } else if (!nombre) {
            setData({
                ...data,
                errorNombre: "Ingresa un nombre",
                errorApellido: false,
                loading: false
            });
        } else if (!apellido) {
            setData({
                ...data,
                errorNombre: false,
                errorApellido: "Ingresa un apellido",
                loading: false
            });
        } else {
            await Axios.post(process.env.REACT_APP_API_BASE_URL + "/usuarios/" + user.id, {
                nombre: nombre,
                apellido: apellido
            }).then(
                (response) => {
                    if (response.data.message && response.data.user) {
                        setUser(response.data.user);
                        setCreado(true)
                        setTextoMensaje(response.data.message);
                        setData({
                            loading: false,
                            errorNombre: "",
                            errorApellido: "",
                            nombre: user.nombre,
                            apellido: user.apellido
                        })
                    }
                }
            );
        }
    }

    return <HelmetProvider>
        <Helmet>
            <title>Cuenta | {process.env.REACT_APP_NAME}</title>
        </Helmet>
        {(creado) ? <Message texto={textoMensaje} /> : ""}
        <div className="containerCuenta">
            <div className="AppBar AppBarCuenta">
                <IconButton onClick={handleLogOut}>
                    <img src={Logout} alt="Icono de cerrar sesion" />
                </IconButton>
            </div>
            <div className="mainUser">
                <div className="infoUser">
                    <p>{(user.nombre && user.apellido) ? user.nombre[0] + user.apellido[0] : ""}</p>
                    <p>{user.nombre + " " + user.apellido}</p>
                    <p>{user.email}</p>
                </div>
                <div className="datosUser">
                    <div className="datos_group">
                        <p>{cantCarpetas}</p>
                        <p>Carpetas</p>
                    </div>
                    <div className="datos_group">
                        <p>{cantMetas}</p>
                        <p>Metas</p>
                    </div>
                </div>
                <div className="userForm">
                    <form onSubmit={handleSubmit}>
                        <TextField
                            name="nombre"
                            onChange={handleChange}
                            value={nombre}
                            style={{ marginBottom: "22px" }}
                            label="Nombre"
                            error={errorNombre ? true : false}
                            helperText={errorNombre} />
                        <TextField
                            name="apellido"
                            onChange={handleChange}
                            value={apellido}
                            style={{ marginBottom: "46px" }}
                            label="Apellido"
                            error={errorApellido ? true : false}
                            helperText={errorApellido} />
                        {(loading) ? <BtnLoading texto={"Editando..."} /> : <BtnSubmit texto={"Editar"} />}
                    </form>
                </div>
            </div>
        </div>
    </HelmetProvider>
}

export default Cuenta;