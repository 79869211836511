import React, { useState, useEffect, useContext } from "react"
import { Helmet, HelmetProvider } from "react-helmet-async";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {
    IconButton,
    FormControl,
    InputLabel,
    Select,
    MenuItem
} from '@mui/material';
import { useNavigate, useParams } from "react-router-dom";
import Chart from "react-apexcharts";
import formatearPrecio from "../hooks/FormatearPrecio"
import Axios from "axios"
import { AuthContext } from "../context/auth";
import traerMes from "../hooks/traerMes"
import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import RefreshIcon from '@mui/icons-material/Refresh';
import traerMontos from "../hooks/traerMontos";

const Graficos = () => {
    const { origen, idOrigen } = useParams();
    const { user } = useContext(AuthContext);
    const [datos, setDatos] = useState([])
    const navigate = useNavigate()
    const [meses, setMeses] = useState([])
    const [years, setYears] = useState([])
    const [data, setData] = useState({
        year: "",
        mes: ""
    })
    const { year, mes } = data

    useEffect(() => {
        if (user.id !== "") {
            Axios.get(process.env.REACT_APP_API_BASE_URL + "/graficos/" + origen + "/" + idOrigen + "/" + user.id).then(
                (responseGet) => {
                    if (responseGet.data.message) {
                        navigate("/carpetas");
                    } else {
                        setMeses(responseGet.data.meses)
                        setYears(responseGet.data.years)
                        if (responseGet.data.years.length > 0) {
                            Axios.post(process.env.REACT_APP_API_BASE_URL + "/graficos/" + origen + "/" + idOrigen + "/", {
                                year: responseGet.data.years[responseGet.data.years.length - 1].year,
                                mes: responseGet.data.meses[responseGet.data.meses.length - 1].mes
                            }).then(
                                (response) => {
                                    setDatos(response.data)
                                    setData({
                                        year: responseGet.data.years[responseGet.data.years.length - 1].year,
                                        mes: responseGet.data.meses[responseGet.data.meses.length - 1].mes
                                    })
                                })
                        }
                    }
                })
        }
    }, [origen, idOrigen, user.id, navigate])

    const handleChange = (e) => {
        setData({ ...data, [e.target.name]: e.target.value });
    };

    const llamarDatos = () => {
        if (year !== "" && mes !== "") {
            Axios.post(process.env.REACT_APP_API_BASE_URL + "/graficos/" + origen + "/" + idOrigen + "/", {
                year: year,
                mes: mes
            }).then(
                (response) => {
                    if (response.data.message) {
                        navigate("/carpetas");
                    } else {
                        setDatos(response.data)
                    }
                })
        }
    }

    const datosPie = {
        series: [datos.totalEgresos, datos.totalIngresos, datos.totalPrestado],
        options: {
            colors: ["#E14560", "#00E396", "#F3A334"],
            chart: {
                width: 500,
                type: 'pie',
            },
            tooltip: {
                y: {
                    formatter: function (val) {
                        return "Gs " + formatearPrecio(val.toString())
                    }
                }
            },
            labels: ['Egresos', 'Ingresos', "Prestado"],
            title: {
                text: "Egresos e ingresos de " + traerMes(mes) + " del " + year.toString(),
                align: "center"
            },
        },
    };

    var datosColumnEgresos = {
        series: (datos.egresos) ? datos.egresos : [],
        options: {
            chart: {
                type: 'bar',
                height: 350
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                    columnWidth: '45%',
                    endingShape: 'rounded'
                },
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                show: true,
                width: 2,
                colors: ['transparent']
            },
            xaxis: {
                categories: [traerMes(mes)],
            },
            fill: {
                opacity: 1
            },
            tooltip: {
                y: {
                    formatter: function (val) {
                        return "Gs " + formatearPrecio(val.toString())
                    }
                }
            },
            yaxis: {
                labels: {
                    formatter: function (val) {
                        return formatearPrecio(val.toString())
                    }
                }
            },
            title: {
                text: "Egresos de " + traerMes(mes) + " del " + year.toString(),
                align: "center"
            },
        },
    };

    var datosColumnIngresos = {

        series: (datos.ingresos) ? datos.ingresos : [],
        options: {
            chart: {
                type: 'bar',
                height: 350
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                    columnWidth: '45%',
                    endingShape: 'rounded'
                },
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                show: true,
                width: 2,
                colors: ['transparent']
            },
            xaxis: {
                categories: [traerMes(mes)],
            },
            fill: {
                opacity: 1
            },
            tooltip: {
                y: {
                    formatter: function (val) {
                        return "Gs " + formatearPrecio(val.toString())
                    }
                }
            },
            yaxis: {
                labels: {
                    formatter: function (val) {
                        return formatearPrecio(val.toString())
                    }
                }
            },
            title: {
                text: "Ingresos de " + traerMes(mes) + " del " + year.toString(),
                align: "center"
            },
        },
    };

    var datosColumnIngresosEgresos = {

        series: (datos.egresosYear && datos.ingresosYear) ? traerMontos(datos.ingresosYear, datos.egresosYear) : [],
        options: {
            colors: ["#E14560", "#00E396"],
            chart: {
                type: 'bar',
                height: 350
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                    columnWidth: '45%',
                    endingShape: 'rounded'
                },
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                show: true,
                width: 2,
                colors: ['transparent']
            },
            xaxis: {
                categories: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Agt', 'Sep', 'Oct', 'Nov', 'Dic'],
            },
            fill: {
                opacity: 1
            },
            title: {
                text: "Egresos e ingresos del " + year.toString(),
                align: "center"
            },
            tooltip: {
                y: {
                    formatter: function (val) {
                        return "Gs " + formatearPrecio(val.toString())
                    }
                }
            },
            yaxis: {
                labels: {
                    formatter: function (val) {
                        return formatearPrecio(val.toString())
                    }
                }
            }
        },
    };

    return <HelmetProvider>
        <Helmet>
            <title>Gráficos | {process.env.REACT_APP_NAME}</title>
        </Helmet>

        <div className="AppBar_graficos">
            <IconButton onClick={() => navigate(-1)}>
                <ArrowBackIcon sx={{ color: "#777575", fontSize: 34 }} />
            </IconButton>
            <p>Ingresos generales</p>
        </div>
        <div className="containerGraficos">
            <div className="datosGraficos">
                <FormControl>
                    <InputLabel id="label-Año">Año</InputLabel>
                    <Select
                        labelId="label-Año"
                        label="Año"
                        name="year"
                        onChange={handleChange}
                        value={year}
                    >
                        {years.map((row, index) => {
                            return (
                                <MenuItem value={row.year} key={index}>
                                    {row.year}
                                </MenuItem>
                            )
                        })}
                    </Select>
                </FormControl>
                <FormControl>
                    <InputLabel id="label-Mes">Mes</InputLabel>
                    <Select
                        labelId="label-Mes"
                        label="Mes"
                        onChange={handleChange}
                        name="mes"
                        value={mes}
                    >
                        {meses.map((row, index) => {
                            return (
                                <MenuItem value={row.mes} key={index}>
                                    {traerMes(row.mes)}
                                </MenuItem>
                            )
                        })}
                    </Select>
                </FormControl>
                <IconButton onClick={llamarDatos}>
                    <RefreshIcon sx={{ color: "#777575" }} />
                </IconButton>
            </div>
            {(datos.totalEgresos > 0 || datos.totalIngresos > 0) ?
                <div className="graficos">
                    <Chart options={datosPie.options}
                        series={datosPie.series}
                        type="pie"
                        width={(window.innerWidth < 400) ? 320 : 500}
                        height={300} />
                    <p>Ingreso neto del mes: Gs {formatearPrecio((datos.totalIngresos - datos.totalEgresos).toString())}</p>
                    <Chart options={datosColumnEgresos.options}
                        series={datosColumnEgresos.series}
                        type="bar"
                        width={(window.innerWidth < 400) ? 320 : 500}
                        height={300} />
                    <Chart options={datosColumnIngresos.options}
                        series={datosColumnIngresos.series}
                        type="bar"
                        width={(window.innerWidth < 400) ? 320 : 500}
                        height={300} />
                    <div className="grafico_largo">
                        <Chart options={datosColumnIngresosEgresos.options}
                            series={datosColumnIngresosEgresos.series}
                            type="bar"
                            width={(window.innerWidth < 400) ? 390 : 600}
                            height={300} />
                    </div>
                </div>
                : <div className="noHayCarpetas">
                    <AutoGraphIcon sx={{ color: "#777575", fontSize: 50 }} />
                    <p>No hay datos para mostrar</p>
                </div>
            }
        </div>
    </HelmetProvider>
}

export default Graficos