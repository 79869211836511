import React from "react"
import formatearPrecio from "../hooks/FormatearPrecio"
import Chart from "react-apexcharts";

const TotalMeta = ({ total, meta }) => {
    var porcentaje = (total * 100) / meta;

    var datosGrafico = {
        series: [parseInt(porcentaje)],
        options: {
            chart: {
                type: 'radialBar',
                offsetY: -20,
                sparkline: {
                    enabled: true
                }
            },
            plotOptions: {
                radialBar: {
                    startAngle: -90,
                    endAngle: 90,
                    track: {
                        background: "#D9D9D9",
                        strokeWidth: '97%',
                        margin: 5, // margin is in pixels
                        dropShadow: {
                            enabled: true,
                            top: 2,
                            left: 0,
                            color: '#999',
                            opacity: 1,
                            blur: 2
                        }
                    },
                    dataLabels: {
                        name: {
                            show: false
                        },
                        value: {
                            offsetY: -2,
                            fontSize: '22px'
                        }
                    }
                }
            },
            grid: {
                padding: {
                    top: -10
                }
            },
            fill: {
                colors: ['#0D7E5D'],
            },
        },


    };

    return <div className="totalMeta">
        <p className="txt_p">Meta: Gs {formatearPrecio(meta.toString())}</p>
        <Chart options={datosGrafico.options}
            series={datosGrafico.series}
            type="radialBar"
            width={(window.innerWidth < 400) ? 320 : 500}
            height={300} />
        <p className="txt_p_2">Gs {formatearPrecio(total.toString())}</p>
    </div>;
}

export default TotalMeta