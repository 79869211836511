import React from "react";
import { Navigate } from "react-router-dom";
import Cookies from "js-cookie";

const PrivateRoute = ({ children }) => {
  if (!Cookies.get("userId")) {
    return <Navigate to="/login" />;
  }

  return children;
};

export default PrivateRoute;
