const formatearPrecio = (precio) => {
  var num = precio.replace(/\./g, "");

  if (!isNaN(num)) {
    num = num
      .toString()
      .split("")
      .reverse()
      .join("")
      .replace(/(?=\d*\.?)(\d{3})/g, "$1.");
    num = num.split("").reverse().join("").replace(/^[\.]/, ""); //eslint-disable-line
  }

  return num;
};

export default formatearPrecio;
