import React, { useState, useContext } from "react"
import { Helmet, HelmetProvider } from "react-helmet-async";
import logo from "../img/iconoBeankeeper.svg";
import { TextField, InputAdornment, IconButton } from '@mui/material';
import "../styles/forms.css"
import { VisibilityOffOutlined, VisibilityOutlined } from "@mui/icons-material";
import Axios from "axios";
import BtnSubmit from "../components/BtnSubmit";
import BtnLoading from "../components/BtnLoading";
import { AuthContext } from "../context/auth";
import { useNavigate, NavLink } from "react-router-dom";

const Signup = () => {
    Axios.defaults.withCredentials = true;

    const [data, setData] = useState({
        email: "",
        password: "",
        nombre: "",
        apellido: "",
        loading: false,
        errorEmail: false,
        errorPassword: false,
        errorNombre: false,
        errorApellido: false
    });
    const { email, password, nombre, apellido, loading, errorEmail,
        errorPassword, errorNombre, errorApellido } = data;
    const [showPass, setShowPass] = useState(false);
    const { setUser } = useContext(AuthContext);
    const navigate = useNavigate();

    const handleChange = (e) => {
        setData({ ...data, [e.target.name]: e.target.value });
    };

    const handlePassword = () => {
        setShowPass(!showPass);
    };

    const validarEmail = (email) => {
        if (
            /* eslint-disable-next-line */
            /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/.test(
                email
            )
        ) {
            return true;
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        setData({
            ...data,
            errorEmail: false,
            errorPassword: false,
            errorApellido: false,
            errorNombre: false,
            loading: true,
        });

        //verificamos si todos los campos estan completos
        var completo = 1;
        var tem = {
            errorEmail: false,
            errorPassword: false,
            errorApellido: false,
            errorNombre: false,
            loading: true,
        };

        Object.entries(data).forEach(([key, value]) => {
            if (value.length === 0) {
                if (key === "email") {
                    tem["error" + key.charAt(0).toUpperCase() + key.substring(1, key.length)] = "Ingresa un correo electrónico";
                } else if (key === "password") {
                    tem["error" + key.charAt(0).toUpperCase() + key.substring(1, key.length)] = "Ingresa una contraseña";
                } else {
                    tem["error" + key.charAt(0).toUpperCase() + key.substring(1, key.length)] = "Ingresa un " + key;
                }
                tem["loading"] = false;
                completo = 0;
            }
        });

        if (!completo) {
            setData({
                ...data,
                errorEmail: tem["errorEmail"],
                errorPassword: tem["errorPassword"],
                errorApellido: tem["errorApellido"],
                errorNombre: tem["errorNombre"],
                loading: tem["loading"],
            });
        } else if (!validarEmail(email)) {
            setData({
                ...data,
                errorEmail: "Ingresa un correo valido",
                loading: false,
            });
        } else {
            Axios.put(process.env.REACT_APP_API_BASE_URL + "/usuarios", {
                email: email,
                password: password,
                nombre: nombre,
                apellido: apellido
            }).then((response) => {
                if (response.data.error === "email") {
                    setData({
                        ...data,
                        errorEmail: response.data.message,
                        errorPassword: false,
                        loading: false
                    });
                } else {
                    Axios.get(process.env.REACT_APP_API_BASE_URL + "/login").then(
                        (response) => {
                            if (response.data.loggedIn) {
                                setUser(response.data.user[0]);
                                navigate("/");
                            }
                        }
                    );
                }
            })
        }
    }

    return <HelmetProvider>
        <Helmet>
            <title>Registrarse | {process.env.REACT_APP_NAME}</title>
        </Helmet>

        <div className="containerSignup">
            <form className="form" onSubmit={handleSubmit}>
                <img className="logoSignup" src={logo} alt="Logo" />
                <h1>Registrarse</h1>
                <p className="txt_sub">Registrate para explorar las funciones</p>
                <TextField
                    name="nombre"
                    onChange={handleChange}
                    value={nombre}
                    style={{ marginBottom: "22px" }}
                    label="Nombre"
                    error={errorNombre ? true : false}
                    helperText={errorNombre} />
                <TextField
                    name="apellido"
                    onChange={handleChange}
                    value={apellido}
                    style={{ marginBottom: "22px" }}
                    label="Apellido"
                    error={errorApellido ? true : false}
                    helperText={errorApellido} />
                <TextField
                    name="email"
                    onChange={handleChange}
                    value={email}
                    style={{ marginBottom: "22px" }}
                    label="Correo electrónico"
                    error={errorEmail ? true : false}
                    helperText={errorEmail} />
                <TextField label="Contraseña"
                    name="password"
                    onChange={handleChange}
                    type={showPass ? "text" : "password"}
                    value={password}
                    error={errorPassword ? true : false}
                    helperText={errorPassword}
                    style={{ marginBottom: "60px" }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton edge="end" onClick={handlePassword}>
                                    {(showPass) ? <VisibilityOffOutlined /> : <VisibilityOutlined />}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }} />
                {(loading) ? <BtnLoading texto={"Registrandose..."} /> : <BtnSubmit texto={"Registrarse"} />}
            </form>
            <NavLink to="/login">
                <div className="div_go_other">
                    <p>Ya tienes una cuenta?</p>
                    <p>Inicia sesión</p>
                </div>
            </NavLink>
        </div>
    </HelmetProvider>
}

export default Signup