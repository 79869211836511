import React, { useState, useContext, useEffect } from "react"
import { useParams } from 'react-router-dom';
import { Helmet, HelmetProvider } from "react-helmet-async";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
    IconButton, Menu, MenuItem, Fab, Dialog,
    DialogContent, TextField,
    Slide, DialogTitle,
    FormControl,
    Select,
    InputLabel,
    FormHelperText,
    Button,
    Autocomplete
} from '@mui/material';
import { useNavigate } from "react-router-dom";
import arrowUp from "../img/icons/arrow_up.svg"
import alert from "../img/icons/alert.svg"
import pagadoIcon from "../img/icons/pagado.svg"
import arrowDown from "../img/icons/arrow_down.svg"
import add from "../img/icons/add.svg"
import { AuthContext } from "../context/auth";
import BtnSubmit from "../components/BtnSubmit";
import BtnLoading from "../components/BtnLoading";
import CloseIcon from "@mui/icons-material/Close";
import formatearPrecio from "../hooks/FormatearPrecio"
import Axios from "axios"
import Message from '../components/Message';
import FolderOffIcon from '@mui/icons-material/FolderOff';
import DeleteIcon from '@mui/icons-material/Delete';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import graph from "../img/icons/graph.svg"
import TotalCarpeta from "../components/TotalCarpeta";
import TotalMeta from "../components/TotalMeta";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const Registros = () => {
    const { origen, idOrigen } = useParams();
    const [registros, setRegistros] = useState([]);
    const [anchorEl, setAnchorEl] = useState(null);
    const [openModal, setOpenModal] = useState(false);
    const openMenu = Boolean(anchorEl);
    const navigate = useNavigate();
    const { user } = useContext(AuthContext);
    const [total, setTotal] = useState(0)
    const [creado, setCreado] = useState(false)
    const [textoMensaje, setTextoMensaje] = useState("")
    const [motivos, setMotivos] = useState([])
    const [nombre, setNombre] = useState("")
    const [meta, setMeta] = useState(0)
    const [data, setData] = useState({
        id: "",
        nombreModal: "",
        motivo: "",
        fecha: "",
        tipo: "",
        monto: "",
        pagado: "no",
        errorMonto: false,
        errorFecha: false,
        errorMotivo: false,
        errorTipo: false,
        loading: false
    });
    const { loading,
        nombreModal,
        fecha,
        id,
        pagado,
        motivo, monto, tipo, errorFecha,
        errorMonto, errorTipo,
        errorMotivo } = data;

    useEffect(() => {
        if (origen !== "meta" && origen !== "carpeta") {
            navigate("/");
        }
        if (user.id !== "") {
            Axios.get(process.env.REACT_APP_API_BASE_URL + "/registros/" + origen + "/" + idOrigen + "/" + user.id).then(
                (response) => {
                    if (response.data.message) {
                        navigate("/");
                    } else {
                        setRegistros(response.data.registros);
                        var sum = 0;
                        for (var i = 0; i < response.data.registros.length; i++) {
                            if (response.data.registros[i].tipo === "ingreso") {
                                sum += response.data.registros[i].monto
                            } else if (response.data.registros[i].tipo === "egreso") {
                                sum -= response.data.registros[i].monto
                            } else if (response.data.registros[i].tipo === "prestado" && response.data.registros[i].pagado === "no") {
                                sum -= response.data.registros[i].monto
                            }

                        }
                        setTotal(sum);
                        setNombre(response.data.nombre);

                        if (response.data.meta) {
                            setMeta(response.data.meta)
                        }
                    }
                }
            );
            Axios.get(process.env.REACT_APP_API_BASE_URL + "/motivos/" + origen + "/" + idOrigen).then(
                (response) => {
                    if (response.data.motivos) {
                        setMotivos(response.data.motivos)
                    }
                })
        }
    }, [creado, user.id, idOrigen, origen, navigate]);

    const handleOpenModal = (registro, nombre) => {
        setOpenModal(true)
        setCreado(false);

        if (registro) {
            registro["nombreModal"] = nombre;
            setData(registro);
        } else {
            setData({ ...data, nombreModal: nombre })
        }

    };

    const handleClickMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const handleCloseModal = (event, reason) => {
        if (reason && reason === "backdropClick") return;
        setOpenModal(false)
        setData({
            motivo: "",
            fecha: "",
            tipo: "",
            monto: "",
        });
    };

    const handleChange = (e) => {
        setData({ ...data, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        setData({
            ...data,
            errorMonto: false,
            errorFecha: false,
            errorMotivo: false,
            errorTipo: false,
            loading: true,
        });

        //verificamos si todos los campos estan completos
        var completo = 1;
        var tem = {
            errorMonto: false,
            errorFecha: false,
            errorMotivo: false,
            errorTipo: false,
            loading: true,
        };

        Object.entries(data).forEach(([key, value]) => {
            if ((key === "monto" || key === "fecha" || key === "motivo" || key === "tipo")
                && value.length === 0) {
                if (key === "fecha") {
                    tem["error" + key.charAt(0).toUpperCase() + key.substring(1, key.length)] = "Ingresa una " + key;
                } else {
                    tem["error" + key.charAt(0).toUpperCase() + key.substring(1, key.length)] = "Ingresa un " + key;
                }
                tem["loading"] = false;
                completo = 0;
            }
        });

        if (!completo) {
            setData({
                ...data,
                errorMonto: tem["errorMonto"],
                errorFecha: tem["errorFecha"],
                errorMotivo: tem["errorMotivo"],
                errorTipo: tem["errorTipo"],
                loading: tem["loading"],
            });
        } else {
            await Axios.put(process.env.REACT_APP_API_BASE_URL + "/registros", {
                monto: parseInt(monto.replaceAll(".", "")),
                motivo: motivo,
                pagado: "no",
                origen: origen,
                fecha: fecha,
                tipo: tipo,
                idOrigen: idOrigen
            }).then((response) => {
                if (response.data.message) {
                    setCreado(true);
                    setTextoMensaje("Registro creado!");
                    handleCloseModal();
                }
            })
        }
    }

    const eliminarRegistro = () => {
        if (id !== "") {
            var result = window.confirm("Seguro de eliminar registro?");
            if (result) {
                Axios.delete(process.env.REACT_APP_API_BASE_URL + "/registros/" + id).then((response) => {
                    if (response.status === 200) {
                        setCreado(true);
                        setTextoMensaje("Registro eliminado!")
                        handleCloseModal();
                    }
                });
            }
        }
    }

    const marcarRegistro = () => {
        if (id !== "") {
            var cambiar = "no";

            if (pagado === "no") {
                cambiar = "si"
            }

            Axios.post(process.env.REACT_APP_API_BASE_URL + "/registros/" + origen + "/" + idOrigen + "/" + user.id + "/" + id,
                {
                    pagado: cambiar
                }
            ).then((response) => {
                if (response.status === 200) {
                    setCreado(true);
                    if (cambiar === "no") {
                        setTextoMensaje("Registro no pagado")
                    } else {
                        setTextoMensaje("Registro pagado")
                    }
                    handleCloseModal();
                }
            });
        }
    }

    const eliminarCarpeta = () => {
        var result = window.confirm("Seguro de eliminar la " + origen + "?");
        if (result) {
            Axios.delete(process.env.REACT_APP_API_BASE_URL + "/carpetas/" + origen + "/" + idOrigen).then((response) => {
                if (response.status === 200) {
                    navigate("/" + origen + "s");
                }
            });
        }
    }

    return <HelmetProvider>
        <Helmet>
            <title>Registros | {process.env.REACT_APP_NAME}</title>
        </Helmet>
        {(creado) ? <Message texto={textoMensaje} /> : ""}
        <div className="containerRegistro">
            <div className="AppBar">
                <IconButton onClick={() => navigate(-1)}>
                    <ArrowBackIcon sx={{ color: "#777575", fontSize: 34 }} />
                </IconButton>
                <p>{nombre}</p>
                <IconButton onClick={handleClickMenu}>
                    <MoreVertIcon sx={{ color: "#777575", fontSize: 34 }} />
                </IconButton>
                <Menu
                    anchorEl={anchorEl}
                    open={openMenu}
                    onClose={handleCloseMenu}
                >
                    <MenuItem sx={{ color: "#f60000 !important" }} onClick={eliminarCarpeta}><p>Eliminar {origen}</p></MenuItem>
                </Menu>
            </div>
            {(registros && registros.length > 0) ?
                <>
                    {(origen === "carpeta") ? <TotalCarpeta total={total} /> : <TotalMeta total={total} meta={meta} />}
                    <div className="registros">
                        {registros.map((row, index) => {
                            return (
                                <div className="registro" key={index} onClick={() => handleOpenModal(row, "Ver registro")}>
                                    <img src={(row.tipo === "egreso") ? arrowDown
                                        : (row.tipo === "ingreso") ? arrowUp : (row.pagado === "no") ? alert : pagadoIcon}
                                        alt={"Icono de " + row.tipo} />
                                    <div className="registro_txts">
                                        <p>Gs {formatearPrecio(row.monto.toString())}</p>
                                        <p>{row.motivo}</p>
                                    </div>
                                </div>
                            );
                        })}
                    </div></> : <div className="noHayCarpetas">
                    <FolderOffIcon sx={{ color: "#777575", fontSize: 50 }} />
                    <p>Todavia no hay registros</p>
                </div>}
            <Fab className="btn_float_registros" onClick={() => handleOpenModal({ ...data }, "Añadir registro")}>
                <img src={add} alt="Icono agregar registro" />
            </Fab>
            <Fab className="btn_float_graficos" onClick={() => navigate("/graficos/" + origen + "/" + idOrigen)}>
                <img src={graph} alt="Icono ir a ver graficos" />
            </Fab>
        </div>
        <Dialog
            className="modal modal_registros"
            open={openModal}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleCloseModal}
            fullWidth={true}
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle id="alert-dialog-title">
                {nombreModal}
                <IconButton
                    onClick={handleCloseModal}
                    aria-label="close"
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <form onSubmit={handleSubmit}>
                    <TextField
                        label="Monto"
                        name="monto"
                        variant="outlined"
                        inputProps={{
                            readOnly: (nombreModal === "Ver registro"),
                        }}
                        onChange={handleChange}
                        value={formatearPrecio(monto.toString())}
                        error={errorMonto ? true : false}
                        helperText={errorMonto}
                        sx={{ marginBottom: "22px" }}
                    />
                    <Autocomplete
                        freeSolo
                        name="motivo"
                        readOnly={(nombreModal === "Ver registro")}
                        disableClearable
                        inputValue={motivo}
                        onChange={(e) => {
                            var pos = motivos.findIndex(
                                (element) => element.motivo === e.target.innerHTML
                            );
                            setData({
                                ...data,
                                motivo: motivos[pos].motivo,
                            });
                        }}
                        options={motivos.map((mo) => mo.motivo)}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Motivo"
                                name="motivo"
                                onChange={handleChange}
                                error={errorMotivo ? true : false}
                                helperText={errorMotivo}
                                sx={{ marginBottom: "22px" }}
                            />
                        )}
                    />
                    <TextField
                        name="fecha"
                        type="date"
                        variant="outlined"
                        InputProps={{
                            inputProps: {
                                max: new Date().toJSON().slice(0, 10).toString(),
                                readOnly: (nombreModal === "Ver registro")
                            }
                        }}
                        onChange={handleChange}
                        value={fecha}
                        error={errorFecha ? true : false}
                        helperText={errorFecha}
                        sx={{ marginBottom: "22px" }}
                    />
                    <FormControl error={errorTipo ? true : false}>
                        <InputLabel id="label-tipo">Tipo</InputLabel>
                        <Select
                            labelId="label-tipo"
                            label="Tipo"
                            name="tipo"
                            inputProps={{
                                readOnly: (nombreModal === "Ver registro"),
                            }}
                            value={tipo}
                            onChange={handleChange}
                        >
                            <MenuItem value={"egreso"}>
                                Egreso
                            </MenuItem>
                            <MenuItem value={"ingreso"}>
                                Ingreso
                            </MenuItem>
                            <MenuItem value={"prestado"}>
                                Prestado
                            </MenuItem>
                        </Select>
                        <FormHelperText>{errorTipo}</FormHelperText>
                    </FormControl>
                    {loading ? <BtnLoading texto={"Añadiendo..."} /> : (nombreModal === "Ver registro") ?
                        <>
                            {(pagado === "no" && tipo === "prestado") ? <Button className="btn btn_pagar"
                                variant="outlined"
                                onClick={marcarRegistro}
                                startIcon={<CheckRoundedIcon />}>
                                Marcar pagado
                            </Button> :
                                (pagado === "si" && tipo === "prestado") ?
                                    <Button className="btn btn_noPagado"
                                        variant="outlined"
                                        onClick={marcarRegistro}
                                        startIcon={<WarningRoundedIcon />}>
                                        Marcar no pagado
                                    </Button>
                                    : ""
                            }
                            <Button className="btn btn_eliminar"
                                variant="contained"
                                onClick={eliminarRegistro}
                                startIcon={<DeleteIcon />}>
                                Eliminar
                            </Button>
                        </>
                        : <BtnSubmit texto={"Añadir"} />
                    }
                </form>
            </DialogContent>
        </Dialog>
    </HelmetProvider >
}

export default Registros