const traerMontos = (listaIngresos, listaEgresos) => {
    var egr = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]

    var ingr = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]

    for (let i = 0; i < listaIngresos.length; i++) {
        ingr[listaIngresos[i].mes - 1] = parseInt(listaIngresos[i].total)
    }

    for (let i = 0; i < listaEgresos.length; i++) {
        egr[listaEgresos[i].mes - 1] = parseInt(listaEgresos[i].total)
    }

    return [{
        name: 'Egresos',
        data: egr
    }, {
        name: 'Ingresos',
        data: ingr
    }]
}

export default traerMontos