import React from "react"
import formatearPrecio from "../hooks/FormatearPrecio"

const TotalCarpeta = ({ total }) => {
    return <div className="totalRegistro">
        <p>Total Registrado</p>
        <p>Gs {formatearPrecio(total.toString())}</p>
    </div>;
}

export default TotalCarpeta