import React, { useState, useContext, useEffect } from "react"
import { Helmet, HelmetProvider } from "react-helmet-async";
import add from "../img/icons/add.svg"
import {
    Fab, Dialog,
    DialogContent,
    Slide, DialogTitle, IconButton, TextField
} from '@mui/material';
import CloseIcon from "@mui/icons-material/Close";
import BtnSubmit from "../components/BtnSubmit";
import BtnLoading from "../components/BtnLoading";
import Axios from "axios";
import { AuthContext } from "../context/auth";
import Message from '../components/Message';
import star from "../img/icons/star.svg"
import FolderOffIcon from '@mui/icons-material/FolderOff';
import { NavLink } from "react-router-dom";
import formatearPrecio from "../hooks/FormatearPrecio"


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const Metas = () => {
    const [creado, setCreado] = useState(false)
    const [openModal, setOpenModal] = useState(false);
    const handleOpenModal = () => setOpenModal(true);
    const [data, setData] = useState({
        nombre: "",
        monto: "",
        errorMonto: false,
        errorNombre: false,
        loading: false
    });
    const { nombre, errorNombre, loading, monto, errorMonto } = data;
    const { user } = useContext(AuthContext);
    const [metas, setMetas] = useState([])

    const handleCloseModal = (event, reason) => {
        if (reason && reason === "backdropClick") return;
        setOpenModal(false)
        setData({ nombre: "", monto: "" });
    };

    const handleChange = (e) => {
        setData({ ...data, [e.target.name]: e.target.value });
    };

    useEffect(() => {
        if (user.id !== "") {
            Axios.get(process.env.REACT_APP_API_BASE_URL + "/metas/" + user.id).then(
                (response) => {
                    setMetas(response.data.metas);
                }
            );
        }
    }, [creado, user.id]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        setData({
            ...data,
            errorNombre: false,
            errorMonto: false,
            loading: true,
        });

        if (nombre === "" && monto === "") {
            setData({
                ...data,
                errorNombre: "Ingresa un nombre",
                errorMonto: "Ingresa un monto",
                loading: false,
            });
        } else if (monto === "") {
            setData({
                ...data,
                errorNombre: false,
                errorMonto: "Ingresa un monto",
                loading: false,
            });
        } else if (nombre === "") {
            setData({
                ...data,
                errorMonto: false,
                errorNombre: "Ingresa un nombre",
                loading: false,
            });
        } else {
            Axios.put(process.env.REACT_APP_API_BASE_URL + "/metas", {
                idUsuario: user.id,
                nombre: nombre,
                meta: parseInt(monto.replaceAll(".", ""))
            }).then((response) => {
                if (response.data.message) {
                    setCreado(true);
                    handleCloseModal();
                }
            })
        }
    }

    return <HelmetProvider>
        <Helmet>
            <title>Metas | {process.env.REACT_APP_NAME}</title>
        </Helmet>
        {(creado) ? <Message texto={"Meta creada!"} /> : ""}
        <div className="presentacion">
            <div className="txts">
                <h1>Tus metas</h1>
                <p>Desarrolla tus metas aquí</p>
            </div>
        </div>
        <div className="containerCarpetas">
            {(metas.length > 0) ? metas.map((row, index) => {
                return (
                    <NavLink to={"/registros/meta/" + row.id} key={index}>
                        <div className="carpeta">
                            <img src={star} alt="Icono de carpeta" />
                            <p>{row.nombre}</p>
                        </div>
                    </NavLink>
                );
            }) : <div className="noHayCarpetas">
                <FolderOffIcon sx={{ color: "#777575", fontSize: 50 }} />
                <p>Todavia no hay metas</p>
            </div>}
        </div>
        <Fab className="btn_float" onClick={() => handleOpenModal()}>
            <img src={add} alt="Icono agregar carpeta" />
        </Fab>
        <Dialog
            className="modal modal_carpetas"
            open={openModal}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleCloseModal}
            fullWidth={true}
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle id="alert-dialog-title">
                {"Añadir meta"}
                <IconButton
                    onClick={handleCloseModal}
                    aria-label="close"
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <form onSubmit={handleSubmit}>
                    <TextField
                        sx={{ marginBottom: "22px" }}
                        label="Nombre"
                        name="nombre"
                        variant="outlined"
                        onChange={handleChange}
                        value={nombre}
                        error={errorNombre ? true : false}
                        helperText={errorNombre}
                    />
                    <TextField
                        label="Monto a alcanzar"
                        name="monto"
                        variant="outlined"
                        onChange={handleChange}
                        value={formatearPrecio(monto)}
                        error={errorMonto ? true : false}
                        helperText={errorMonto}
                    />
                    {loading ? <BtnLoading texto={"Creado meta..."} /> : <BtnSubmit texto={"Añadir"} />}
                </form>
            </DialogContent>
        </Dialog>
    </HelmetProvider>
}

export default Metas;