import { createContext, useEffect, useState } from "react";
import Axios from "axios";

export const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  Axios.defaults.withCredentials = true;

  const [user, setUser] = useState({
    id: "",
    nombre: "",
    apellido: "",
    email: ""
  });

  useEffect(() => {
    Axios.get(process.env.REACT_APP_API_BASE_URL + "/login").then(
      (response) => {
        if (response.data.loggedIn) {
          setUser(response.data.user[0]);
        }
      }
    );
  }, []);

  return (
    <AuthContext.Provider value={{ user, setUser }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
