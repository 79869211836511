import React, { useState, useContext } from "react"
import { Helmet, HelmetProvider } from "react-helmet-async";
import logo from "../img/iconoBeankeeper.svg";
import { TextField, InputAdornment, IconButton } from '@mui/material';
import "../styles/forms.css"
import { VisibilityOffOutlined, VisibilityOutlined } from "@mui/icons-material";
import Axios from "axios";
import BtnSubmit from "../components/BtnSubmit";
import BtnLoading from "../components/BtnLoading";
import { AuthContext } from "../context/auth";
import { useNavigate } from "react-router-dom";
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import jwt_decode from "jwt-decode";
import { NavLink } from "react-router-dom";

const Login = () => {
    Axios.defaults.withCredentials = true;

    const [data, setData] = useState({
        email: "",
        password: "",
        loading: false,
        errorEmail: false,
        errorPassword: false
    });
    const { email, password, loading, errorEmail, errorPassword } = data;
    const [showPass, setShowPass] = useState(false);
    const { setUser } = useContext(AuthContext);
    const navigate = useNavigate();

    const handleChange = (e) => {
        setData({ ...data, [e.target.name]: e.target.value });
    };

    const handlePassword = () => {
        setShowPass(!showPass);
    };

    const validarEmail = (email) => {
        if (
            /* eslint-disable-next-line */
            /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/.test(
                email
            )
        ) {
            return true;
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        setData({
            ...data,
            errorEmail: false,
            errorPassword: false,
            loading: true,
        });

        if (!email && !password) {
            setData({
                ...data,
                errorEmail: "Ingresa un correo electrónico",
                errorPassword: "Ingresa una contraseña",
                loading: false
            });
        } else if (!validarEmail(email)) {
            setData({
                ...data,
                errorEmail: "Ingresa un correo valido"
            });
        }
        else if (!email) {
            setData({
                ...data,
                errorEmail: "Ingresa un correo electrónico",
                loading: false
            });
        } else if (!password) {
            setData({
                ...data,
                errorPassword: "Ingresa una contraseña",
                loading: false
            });
        } else {
            Axios.post(process.env.REACT_APP_API_BASE_URL + "/login", {
                email: email,
                password: password
            }).then((response) => {
                if (response.data.error === "email") {
                    setData({
                        ...data,
                        errorEmail: response.data.message,
                        errorPassword: false,
                        loading: false
                    });
                } else if (response.data.error === "password") {
                    setData({
                        ...data,
                        errorEmail: false,
                        errorPassword: response.data.message,
                        loading: false
                    });
                } else {
                    Axios.get(process.env.REACT_APP_API_BASE_URL + "/login").then(
                        (response) => {
                            if (response.data.loggedIn) {
                                setUser(response.data.user[0]);
                                navigate("/");
                            }
                        }
                    );
                }
            })
        }
    }

    return <HelmetProvider>
        <Helmet>
            <title>Iniciar sesión | {process.env.REACT_APP_NAME}</title>
        </Helmet>

        <div className="containerLogin">
            <form className="form" onSubmit={handleSubmit}>
                <img className="logo" src={logo} alt="Logo" />
                <h1>Iniciar sesión</h1>
                <p className="txt_sub">Ingresa a tu cuenta para continuar</p>
                <TextField
                    name="email"
                    onChange={handleChange}
                    value={email}
                    style={{ marginBottom: "22px" }}
                    label="Correo electrónico"
                    error={errorEmail ? true : false}
                    helperText={errorEmail} />
                <TextField label="Contraseña"
                    name="password"
                    onChange={handleChange}
                    type={showPass ? "text" : "password"}
                    value={password}
                    error={errorPassword ? true : false}
                    helperText={errorPassword}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton edge="end" onClick={handlePassword}>
                                    {(showPass) ? <VisibilityOffOutlined /> : <VisibilityOutlined />}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }} />
                <p className="link_olvide">Olvide mi contraseña</p>
                {(loading) ? <BtnLoading texto={"Iniciando sesión..."} /> : <BtnSubmit texto={"Iniciar sesión"} />}
                <div className="btn_google">
                    <GoogleOAuthProvider clientId="313770815336-htjl21vkffuh49q8dke3ar5nvdclmvto.apps.googleusercontent.com">
                        <GoogleLogin
                            onSuccess={credentialResponse => {
                                var decoded = jwt_decode(credentialResponse.credential);
                                Axios.post(process.env.REACT_APP_API_BASE_URL + "/loginGoogle", {
                                    email: decoded.email,
                                    nombre: decoded.given_name,
                                    apellido: decoded.family_name
                                }).then((response) => {
                                    Axios.get(process.env.REACT_APP_API_BASE_URL + "/login").then(
                                        (response) => {
                                            if (response.data.loggedIn) {
                                                setUser(response.data.user[0]);
                                                navigate("/");
                                            }
                                        }
                                    );

                                })
                            }}
                        />
                    </GoogleOAuthProvider>
                </div>
            </form>
            <NavLink to="/signup">
                <div className="div_go_other">
                    <p>No tienes una cuenta?</p>
                    <p>Registrate</p>
                </div>
            </NavLink>
        </div>
    </HelmetProvider>
}

export default Login