const traerMomentoDia = () => {
    var momento = ""
    var today = new Date();

    // obtener la hora actual
    var hora = today.getHours();

    //dia: 1 y las 11
    //tarde: 12 y las 19
    //noche: 20 y las 0

    if (hora > 1 && hora <= 11) {
        momento = "Buenos dias"
    } else if (hora >= 12 && hora <= 19) {
        momento = "Buenas tardes"
    } else {
        momento = "Buenas noches"
    }

    return momento
}

export default traerMomentoDia
