import React from "react"
import home from "../img/icons/home.svg"
import person from "../img/icons/person.svg"
import folder from "../img/icons/folder.svg"
import star from "../img/icons/star.svg"
import starFilled from "../img/icons/star_filled.svg"
import personFilled from "../img/icons/person_filled.svg"
import homeFilled from "../img/icons/home_filled.svg"
import folderFilled from "../img/icons/folder_filled.svg"
import "../styles/bottomNav.css"
import { NavLink } from "react-router-dom";

const BottomNav = ({ children }) => {
    return <div className="contenedorMain">
        <main>{children}</main>
        <div className="containerBottomNav">
            <NavLink
                to="/"
            >
                {({ isActive }) => (
                    <div className="iconGroup">
                        <img src={isActive ? homeFilled : home} alt="Icono de inicio" />
                        <p className={isActive ? "link-active" : ""}>Inicio</p>
                    </div>
                )}
            </NavLink>
            <NavLink
                to="/carpetas"
            >
                {({ isActive }) => (
                    <div className="iconGroup">
                        <img src={isActive ? folderFilled : folder} alt="Icono de carpetas" />
                        <p className={isActive ? "link-active" : ""}>Carpetas</p>
                    </div>
                )}
            </NavLink>
            <NavLink
                to="/metas"
            >
                {({ isActive }) => (
                    <div className="iconGroup">
                        <img src={isActive ? starFilled : star} alt="Icono de metas" />
                        <p className={isActive ? "link-active" : ""}>Metas</p>
                    </div>
                )}
            </NavLink>
            <NavLink
                to="/cuenta"
            >
                {({ isActive }) => (
                    <div className="iconGroup">
                        <img src={isActive ? personFilled : person} alt="Icono de cuenta" />
                        <p className={isActive ? "link-active" : ""}>Cuenta</p>
                    </div>
                )}
            </NavLink>
        </div>
    </div>
}

export default BottomNav