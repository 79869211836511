import React, { useContext } from "react"
import { Helmet, HelmetProvider } from "react-helmet-async";
import "../styles/paginas.css"
import { AuthContext } from "../context/auth";
import traerMomentoDia from "../hooks/traerMomentoDia";

const Home = () => {
    const { user } = useContext(AuthContext);

    return <HelmetProvider>
        <Helmet>
            <title>{process.env.REACT_APP_NAME}</title>
        </Helmet>

        <div className="presentacion">
            <div className="txts">
                <p>Hola {user.nombre}!</p>
                <p>{traerMomentoDia()}</p>
            </div>
            <div className="img_perfil">
                <p>{(user.nombre && user.apellido) ? user.nombre[0] + user.apellido[0] : ""}</p>
            </div>
        </div>
    </HelmetProvider>
}

export default Home; 