import './App.css';
import Login from './pages/Login';
import { HashRouter, Routes, Route } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import AuthProvider from "./context/auth";
import PrivateRoute from "./components/privateRoute";
import Home from "./pages/Home"
import Signup from "./pages/Signup"
import Carpetas from "./pages/Carpetas"
import Metas from "./pages/Metas"
import Cuenta from "./pages/Cuenta"
import BottomNav from './components/BottomNav';
import Registros from './pages/Registros';
import Graficos from './pages/Graficos';

function App() {
  const theme = createTheme({
    palette: {
      primary: {
        main: "#0D7E5D",
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <AuthProvider>
        <HashRouter>
          <Routes>
            <Route exact path="/login" element={<Login />} />
            <Route exact path="/signup" element={<Signup />} />
            <Route
              path="/"
              element={
                <PrivateRoute>
                  <BottomNav children={<Home />} />
                </PrivateRoute>
              }
            />
            <Route
              path="/carpetas"
              element={
                <PrivateRoute>
                  <BottomNav children={<Carpetas />} />
                </PrivateRoute>
              }
            />
            <Route
              path="/metas"
              element={
                <PrivateRoute>
                  <BottomNav children={<Metas />} />
                </PrivateRoute>
              }
            />
            <Route
              path="/cuenta"
              element={
                <PrivateRoute>
                  <BottomNav children={<Cuenta />} />
                </PrivateRoute>
              }
            />
            <Route
              path="/registros/:origen/:idOrigen"
              element={
                <PrivateRoute>
                  <Registros />
                </PrivateRoute>
              }
            />
            <Route
              path="/graficos/:origen/:idOrigen"
              element={
                <PrivateRoute>
                  <Graficos />
                </PrivateRoute>
              }
            />
          </Routes>
        </HashRouter>
      </AuthProvider>
    </ThemeProvider >
  );
}

export default App;
